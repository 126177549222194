import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql, Link } from "gatsby";
import { MdEmail } from "react-icons/md";
import { GatsbyImage } from "gatsby-plugin-image";
import Airtable from "airtable";
import { jsonrepair } from "jsonrepair";
import { Card, Spinner } from "react-bootstrap";
import moment from "moment";
import Form from "react-bootstrap/Form";
import PreferToTalk from "../components/prefer-to-talk";
import { FaMapMarkerAlt, FaPhone } from "react-icons/fa";
import { IoPersonSharp, IoDocumentOutline } from "react-icons/io5";

const ViewingsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			allAirtableProperties(filter: { isFloor: { nin: 1 } }) {
				edges {
					node {
						addressLineOne
						addressLineTwo
						city
						postCode
						desksFrom
						desksTo
						subwayStation1
						subwayStation1DistanceKm
						subwayStation1DurationMins
						subwayStation2
						subwayStation2DistanceKm
						subwayStation2DurationMins
						subwayStation3
						subwayStation3DistanceKm
						subwayStation3DurationMins
						type
						rentPM
						airtableId
						locationLatitude
						locationLongitude
						name

						features
						description

						photosNew {
							childImageSharp {
								gatsbyImageData(
									quality: 30
									placeholder: BLURRED
									formats: AUTO
								)
							}
						}
					}
				}
			}
			# matt: wpMediaItem(title: { eq: "Matt New" }) {
			# 	altText
			# 	localFile {
			# 		childImageSharp {
			# 			gatsbyImageData(
			# 				formats: [AUTO, WEBP]
			# 				quality: 100
			# 				transformOptions: { cropFocus: CENTER, fit: CONTAIN }
			# 				layout: CONSTRAINED
			# 				placeholder: BLURRED
			# 			)
			# 		}
			# 	}
			# }
			# andrew: wpMediaItem(title: { eq: "Andrew New" }) {
			# 	altText
			# 	localFile {
			# 		childImageSharp {
			# 			gatsbyImageData(
			# 				formats: [AUTO, WEBP]
			# 				quality: 100
			# 				transformOptions: { cropFocus: CENTER, fit: CONTAIN }
			# 				layout: CONSTRAINED
			# 				placeholder: BLURRED
			# 			)
			# 		}
			# 	}
			# }
			# tony: wpMediaItem(title: { eq: "Tony" }) {
			# 	altText
			# 	localFile {
			# 		childImageSharp {
			# 			gatsbyImageData(
			# 				formats: [AUTO, WEBP]
			# 				quality: 100
			# 				transformOptions: { cropFocus: CENTER, fit: CONTAIN }
			# 				layout: CONSTRAINED
			# 				placeholder: BLURRED
			# 			)
			# 		}
			# 	}
			# }
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	var queryParameters = "";
	var uniqueId = "";
	var tempVArray = [""];
	const [bookingEmail, setBookingEmail] = useState("");
	const [bookingProperties, setBookingProperties] = useState("");
	const [idOfProperties, setIdOfProperties] = useState([]);
	const [viewingJsonTemp, setViewingJsonTemp] = useState([]);
	const [viewingJson, setViewingJson] = useState([]);
	const [matchingProperties, setMatchingProperties] = useState([]);
	const [combinedProperties, setCombinedProperties] = useState([]);
	const [activeComment, setActiveComment] = useState([]);
	const [commentValues, setCommentValues] = useState([]);
	const [bookingCompany, setBookingCompany] = useState("");
	const [loading, setLoading] = useState(true);
	const [uniqueIdCheck, setUniqueIdCheck] = useState(true);
	const [hiddenDescription, setHiddenDescription] = useState(true);
	const [activeCard, setActiveCard] = useState(null);
	const [activeDescriptionCard, setActiveDescriptionCard] = useState(null);
	// const andrew = data.andrew.localFile.childImageSharp.gatsbyImageData;
	// const matt = data.matt.localFile.childImageSharp.gatsbyImageData;
	// const tony = data.tony.localFile.childImageSharp.gatsbyImageData;

	//   const handleDescriptionClick = (index) => {
	// 	const tempActiveDescriptionCard = [...activeDescriptionCards, index]
	// 	setActiveDescriptionCards
	//   }
	//additonal comment for build testing
	const handleDescriptionClick = (index) => {
		setActiveDescriptionCard(index);
	};

	useEffect(() => {
		if (typeof Window !== "undefined") {
			queryParameters = new URLSearchParams(window.location.search);
			uniqueId = queryParameters.get("id") || "";

			if (uniqueId === "") {
				setUniqueIdCheck(false);
				setLoading(false);
			}
		}
	}, []);

	const siteUrl = data.site.siteMetadata.siteUrl;
	var base = new Airtable({
		apiKey:
			"patwBgrJRJhwWMsH4.77226508a7a8dff515fa7a8707a86746e86888f0c65ef73d5057a19b6f42afb0",
	}).base("appV4VgV8hfFmA0uO");

	useEffect(() => {
		base("Bookings")
			.select({
				filterByFormula: `{uid} = "${uniqueId}"`,
			})
			.eachPage(
				function page(records, fetchNextPage) {
					records.forEach(function (record) {
						// console.dir(record); // show full record JS object
						setBookingEmail(record.fields["Email"]);
						setBookingProperties(record.fields["Properties"]);
						setBookingCompany(record.fields["Company"]);
						var tempId = Array.prototype.slice.call(
							record.fields["Connected Viewings"]
						);
						setIdOfProperties(tempId);

						var tempviewing = Array.prototype.slice.call(
							record.fields["Viewing Json"]
						);

						setViewingJsonTemp(tempviewing);
					});
					fetchNextPage();
				},
				function done(err) {
					if (err) {
						console.error(err);
						return;
					}
				}
			);
	}, []);

	useEffect(() => {
		if (viewingJsonTemp?.length > 0) {
			var testparsedarray = [];
			viewingJsonTemp.forEach(function (val) {
				testparsedarray.push(JSON.parse(jsonrepair(val)));
			});

			setViewingJson(testparsedarray);
		}
	}, [viewingJsonTemp]);

	useEffect(() => {
		if (viewingJson?.length > 0) {
			var testarray = [];
			viewingJson.forEach(function (val) {
				testarray.push(
					data.allAirtableProperties.edges.find(
						(o) => o.node.airtableId === val.propertyId
					)?.node
				);
			});

			setMatchingProperties(testarray);
		}
	}, [viewingJson]);

	useEffect(() => {
		if (matchingProperties?.length > 0) {
			var testarray = viewingJson.map(function (value, index) {
				return { value, ...matchingProperties[index] };
			});

			setCombinedProperties(testarray);
		}
	}, [matchingProperties]);

	const HandleSubmit = (id, val) => {
		setActiveCard(null);
		if (val !== "") {
			var tempArray = commentValues.slice();
			tempArray.push({
				value: val,
				id: id,
			});

			setCommentValues(tempArray);

			base("Viewings").update(
				[
					{
						id: id,
						fields: {
							Comment: val,
						},
					},
				],
				{ typecast: true },
				function (err, records) {
					if (err) {
						console.error(err);
						return;
					}
				}
			);
		}
	};

	const DeleteComment = (id) => {
		const tempArray = commentValues.filter((el) => el.id !== id);

		setCommentValues(tempArray);

		base("Viewings").update(
			[
				{
					id: id,
					fields: {
						Comment: "",
					},
				},
			],
			{ typecast: true },
			function (err, records) {
				if (err) {
					console.error(err);
					return;
				}
			}
		);
	};

	useEffect(() => {
		if (combinedProperties?.length > 0) {
			var testarray = [];

			combinedProperties.forEach((propertyNew) => {
				if (propertyNew.value.comment !== "") {
					testarray.push({
						value: propertyNew.value.comment,
						id: propertyNew.value.atId,
					});
				}
				setLoading(false);
			});

			setCommentValues(testarray);
		}
	}, [combinedProperties]);

	const firstProperty = combinedProperties[0];
	console.log(combinedProperties);
	return (
		<Layout>
			<Helmet></Helmet>
			<GatsbySeo title={`${bookingCompany} Shortlist`} language="en" noindex />
			<section className="bg-primary  py-4  mb-4">
				<Container>
					<Row>
						<Col className="text-start text-white">
							<h1>
								{bookingCompany}
								{bookingCompany ? "'s" : ""} Shortlist
							</h1>
							<p className="text-white fw-bold">VIEW MY PROPERTIES</p>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="pb-6">
				<Container>
					<Row>
						<Col lg={{ offset: 3, span: 6 }}>
							<div
								className={`w-100 position-relative ${
									!loading && !uniqueIdCheck ? "" : "d-none"
								}`}
								style={{ height: "200px" }}
							>
								<span className="position-absolute bottom-0 start-50 translate-middle-x text-center pb-6 pb-lg-0">
									<p className="fs-4">
										No viewings yet. See our{" "}
										<Link to="/properties" className=" fw-bold">
											properties
										</Link>{" "}
										to book your first viewings
									</p>
								</span>
							</div>
							<div
								className={`w-100 position-relative ${loading ? "" : "d-none"}`}
								style={{ height: "200px" }}
							>
								<span className="position-absolute bottom-0 start-50 translate-middle-x text-center pb-6 pb-lg">
									<Spinner animation="border" variant="primary" />
									<p>properties loading...</p>
								</span>
							</div>
						</Col>
					</Row>
					<Row className="h-100 g-4 mb-4">
						{combinedProperties.map((viewing, i) => (
							<Col className="h-100" id={i} lg={4} md={6} xs={12}>
								<Card
									style={{ borderRadius: "0px" }}
									className="w-100 h-100 p-0 m-0 border-0 "
								>
									<Card.Img
										style={{
											maxHeight: "300px",
											minHeight: "300px",
											borderRadius: "0px",
										}}
										as={GatsbyImage}
										variant="top"
										image={
											viewing.photosNew[0].childImageSharp?.gatsbyImageData
										}
										alt={viewing.name}
									/>
									<Card.Body>
										<Card.Title className="mb-3">{viewing.name}</Card.Title>
										<Card.Text>
											<p
												style={{ fontSize: "90%" }}
												className="text-black mb-3"
											>
												<FaMapMarkerAlt className="text-med-grey" />{" "}
												{`${
													viewing.addressLineOne
														? `${viewing.addressLineOne}`
														: ""
												}${
													viewing.addressLineTwo
														? `, ${viewing.addressLineTwo}`
														: ""
												}${viewing.city ? `, ${viewing.city}` : ""}${
													viewing.postCode ? `, ${viewing.postCode}` : ""
												}`}
											</p>

											<Row className="px-2">
												<Col xs={12} className="text-black mb-2">
													<span>Viewing Date:</span>{" "}
													<strong>
														{`${
															viewing.value.confirmedDate === ""
																? "TBC"
																: `${moment(
																		new Date(viewing.value.confirmedDate)
																  ).format("DD MMMM yy")}`
														}`}
													</strong>
												</Col>

												<Col xs={6} className="text-black mb-2 ">
													<span>Viewing Time:</span>{" "}
													<strong>
														{`${
															viewing.value.confirmedDate === ""
																? "TBC"
																: `${moment(
																		new Date(viewing.value.confirmedDate)
																  ).format("HH:mm")}`
														}`}
													</strong>
												</Col>
												<Col xs={6}>
													<p className="text-black mb-3 text-end">
														<span>Quote:</span>{" "}
														<span className="fw-bold">
															{`${
																viewing.value.quote === ""
																	? "TBC"
																	: `${viewing.value.quote.toLocaleString(
																			"en-GB"
																	  )}`
															}`}
														</span>
													</p>
												</Col>
											</Row>

											<Row className="px-2 mb-2">
												{viewing.value.document && (
													<Col className="d-flex justify-content-end" xs={6}>
														<a
															target="_blank"
															rel="noreferrer"
															href={viewing.value.document}
														>
															{" "}
															<div className="d-flex align-items-center w-100 text-end">
																<IoDocumentOutline className="me-1" />
																Download Quote
															</div>
														</a>
													</Col>
												)}
											</Row>

											{viewing.value.alternateContact && (
												//   <div className="bg-primary bg-opacity-10 mb-3 p-3">
												//     <h3 className="fs-5">
												//       Contact details of third party contact
												//     </h3>
												//     <p className="m-0">
												//       <MdPerson className="fs-5" />
												//       {viewing.value.contactsName}
												//     </p>
												//     <p className="m-0">
												//       <MdEmail style={{ fontSize: "90% !important" }} />
												//       {viewing.value.contactsEmail}
												//     </p>
												//     <p className="m-0">
												//       <FaPhone className="fs-6" />
												//       {viewing.value.contactsTelephone}
												//     </p>
												//   </div>
												<Row className="mb-2">
													<div className="mb-3 px-3">
														<h3 className="fs-5">
															You will be shown around by:
														</h3>
														<div className=" ps-2">
															<div className="m-0 d-flex align-items-center">
																<IoPersonSharp className="fs-6 me-1 text-med-grey" />
																<span className="text-med-grey">
																	{viewing.value.contactsName}
																</span>
															</div>
															<a
																className="grey-link"
																href={`mailto:${viewing.value.contactsEmail}`}
															>
																<div className="m-0 d-flex align-items-center">
																	<MdEmail
																		className="me-1"
																		style={{ fontSize: "90% !important" }}
																	/>
																	<span>{viewing.value.contactsEmail}</span>
																</div>
															</a>
															<a
																className="grey-link"
																href={`tel:${viewing.value.contactsTelephone}`}
															>
																<div className="m-0 d-flex align-items-center">
																	<FaPhone className="fs-6 me-1" />
																	<span>{viewing.value.contactsTelephone}</span>
																</div>
															</a>
														</div>
													</div>
												</Row>
											)}

											<div>
												<p
													className={`viewing-description ${
														activeDescriptionCard !== i ? "description" : ""
													}`}
													style={{ cursor: "pointer" }}
													onClick={() => {
														setActiveDescriptionCard(i);
													}}
													onMouseLeave={() => setActiveDescriptionCard(null)}
												>
													{viewing.description}
												</p>
											</div>
											{commentValues?.length > 0 &&
												commentValues.find(
													(o) => o.id === viewing.value.atId
												) && (
													<div className="d-flex justify-content-between pe-4">
														<p className="d-inline-block text-black">
															{
																commentValues.find(
																	(o) => o.id === viewing.value.atId
																).value
															}
														</p>
														<p
															onClick={() => DeleteComment(viewing.value.atId)}
															size="small"
															className="d-inline-block text-primary text-end"
															id="contact-send-btn"
															style={{ cursor: "pointer" }}
														>
															Delete Comment
														</p>
													</div>
												)}
										</Card.Text>

										{!commentValues.find(
											(o) => o.id === viewing.value.atId
										) && (
											<Col xs={12} className="">
												{!(activeCard === i) && (
													<p
														onClick={() => setActiveCard(i)}
														size="small"
														className="text-primary mt-3 ps-2"
														style={{ cursor: "pointer" }}
													>
														Add your comment
													</p>
												)}
												{activeCard === i && (
													<Form className="w-100">
														<Form.Group className="mb-3" controlId="comment">
															<Form.Control
																name="comment"
																id={viewing.value.atId}
																placeholder="Comment"
																as="textarea"
																rows={3}
															/>
														</Form.Group>

														<p
															onClick={() =>
																HandleSubmit(
																	viewing.value.atId,
																	document.getElementById(viewing.value.atId)
																		.value
																)
															}
															size="small"
															className="text-primary mt-3 ps-2"
															id="contact-send-btn"
															style={{ cursor: "pointer" }}
														>
															Save comment
														</p>
													</Form>
												)}
											</Col>
										)}
									</Card.Body>
								</Card>
							</Col>
						))}
					</Row>
					<Row>
						<Col xs={12} lg={{ span: 6, offset: 3 }}>
							<div
								className={`px-6 ${loading || !uniqueIdCheck ? "" : "d-none"}`}
							>
								<PreferToTalk title="Ready to talk?" />
							</div>
							<div className="px-lg-6 px-2">
								{firstProperty?.value.accountManager === "" && (
									<PreferToTalk title="Ready to talk?" />
								)}
								{firstProperty?.value.accountManager === "Matt Fielden" && (
									<PreferToTalk
										// img={matt}
										// imgAlt={data.matt.altText}
										hasImage
										phone="07462 626 057"
										email="agnes@assetoffices.co.uk"
										message="Speak to Matt about your shortlist or any changes you wish to make."
										title={`Want to contact Matt Fielden?`}
									/>
								)}
								{firstProperty?.value.accountManager === "Tony Beck" && (
									<PreferToTalk
										// img={tony}
										// imgAlt={data.tony.altText}
										hasImage
										phone="07947 522 101"
										email="agnes@assetoffices.co.uk"
										message="Speak to Tony about your shortlist or any changes you wish to make."
										title="Want to contact Tony Beck?"
									/>
								)}
								{firstProperty?.value.accountManager === "Andrew Hogan" && (
									<PreferToTalk
										// img={andrew}
										// imgAlt={data.andrew.altText}
										hasImage
										phone="07901 565 288"
										email="agnes@assetoffices.co.uk"
										message="Speak to Andrew about your shortlist or any changes you wish to make."
										title="Want to contact Andrew Hogan?"
									/>
								)}
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default ViewingsPage;
