import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { useStaticQuery, graphql, Link } from "gatsby";

function PreferToTalk({
	title,
	message,
	phone,
	email,
	yPadding,
	hasImage,
	img,
	imgAlt,
}) {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			heroInterior: wpMediaItem(title: { eq: "Person-4" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: FULL_WIDTH
							placeholder: BLURRED
						)
					}
				}
			}
		}
	`);

	const talkPhone = phone || "01323 385 385";
	const talkEmail = email || "info@yourdesk.co.uk";

	return (
		<div className=" position-relative mb-4">
			<GatsbyImage
				image={data.heroInterior.localFile.childImageSharp.gatsbyImageData}
				className="w-100  object-fit-cover  "
				alt={data.heroInterior.altText}
			/>
			<div
				className={` position-absolute w-100  bottom-0 p-2 start-0  `}
				// style={{ opacity: "0%", borderRadius: "1rem" }}
			>
				<div style={{ background: "rgba(8, 69, 141, 0.62)" }} className="p-3">
					<h4 className="text-white fs-5">{title || "Ready to talk?"}</h4>
					<p style={{ fontSize: "90%" }} className="text-white">
						{message ||
							"Speak to our experts about your requirements and get a personalised shortlist."}
					</p>
					<div className="d-flex align-items-center text-white">
						<FaPhone className="text-white fs-6 me-2" />
						<a
							style={{ fontSize: "90%" }}
							className="text-white p-0 m-0"
							href={`tel:${talkPhone}`}
						>
							{talkPhone}
						</a>
					</div>
					<div className="d-flex pt-3 pt-lg-0 align-items-center">
						<MdEmail className="text-white fs-6 me-2" />
						<a
							style={{ fontSize: "90%" }}
							className="text-white p-0 m-0"
							href={`mailto:${talkEmail}`}
						>
							{talkEmail}
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
export default PreferToTalk;
